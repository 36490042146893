<template>
  <div>
  <el-table
    ref="table"
    :data="tableData"
    style="width: 100%">
    <el-table-column type="expand">
      <template slot-scope="props">
        <div class="content">
          <h5>岗位职责</h5>
          <div v-html="props.row.responsibility"></div>
          <el-divider></el-divider>
          <h5>岗位要求</h5>
          <div v-html="props.row.jobRequirements"></div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="岗位名称"
      prop="postName">
    </el-table-column>
    <el-table-column
      label="岗位类别"
      prop="postCategory">
    </el-table-column>
    <el-table-column
      label="工作地点"
      prop="workingPlace">
    </el-table-column>
    <el-table-column
      label="发布时间"
      prop="createTime">
    </el-table-column>
    <el-table-column>
      <template slot-scope="scope">
        <el-button @click="toogleExpand(scope.row)" type="text">
          了解详情
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <page-bean
      @clickPage="clickPage"
      :total="totlePage"
    ></page-bean>
  </div>
  </div>
</template>
<script>
import pageBean from "@/components/pageBean.vue";
import { owRecruitingList } from "@/api/api";
export default {
  name: "zpgw",
  components: {
    pageBean,
  },
  data(){
    return {
      totlePage: 0,
      queryParams: {
        currentPage: 1,
        pageSize: 10,
      },
      tableData: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      let params = this.queryParams
      owRecruitingList(params).then((res) => {
        if (res.success == true) {
          this.tableData = res.data.records.reverse();
          this.totlePage = res.data.total;
        }
      });
    },
    clickPage(obj) {
      this.queryParams.pageSize = obj.pageSize;
      this.queryParams.currentPage = obj.currentPage;
      this.getList();
    },
    toogleExpand(row) {
      let table = this.$refs.table;
      table.toggleRowExpansion(row);
    },
  },
}
</script>
<style lang="scss" scoped>
.content{
  padding: 20px 60px;
  background: #f6f6f6;
  border-radius: 10px;
  letter-spacing: 1px;
  div{
    margin-bottom: 10px;
  }
  /deep/h5{
    margin-bottom: 20px;
    font-size: 16px;
  }
  /deep/p{
    font-size: 13px;
    line-height: 30px;
  }
}
.el-divider{
  margin-bottom: 20px!important;
}
/deep/.el-table th.el-table__cell>.cell{
  color: #333333;
}
.el-table
/deep/ thead{
  color: #333333;
  th{
    background-color: #f6f6f6;
    &:nth-child(1){
      border-radius: 10px 0 0 10px;
    }
    &:nth-child(6){
      border-radius: 0 10px 10px 0;
    }
  }
}
/deep/ .el-table__expand-icon{
  // display: none;
}
</style>
