<template>
  <div>
    <baidu-map @ready='initMap'></baidu-map>
  </div>
</template>
<script>
export default {
  name: "mapLocal",
  data(){
    return {
      map: null,
      data : [118.748000, 32.096000],
      marker: null,
    }
  },
  mounted() {
   
  },
  methods: {
    initMap({BMap,map}){
      this.map = map;
      let data = this.data;
      let point = new BMap.Point(data[0], data[1])  //设置城市经纬度坐标
      this.map.centerAndZoom(point, 16)    //
      this.map.setCurrentCity('南京')   // 设置地图显示的城市 此项是必须设置的
      this.map.enableScrollWheelZoom(true)    //鼠标滚动缩放
      this.marker = new BMap.Marker(new BMap.Point(data[0], data[1])); 
      this.map.addOverlay(this.marker);  
      this.addClickHandler()  
      this.openInfo();
    },
    addClickHandler(){
      let self = this;
      this.marker.addEventListener("click",function(){
        self.openInfo();
      });
    },
    openInfo(){
      let opts = {
        min_width: 250,      
        min_height: 80,     
        title: "江苏禹江科技有限公司",
        content: "地址：江苏省南京市鼓楼区郑和中路112号长江航运中心C座22楼",
      };
      let point = new BMap.Point(this.data[0], this.data[1]);
      let infoWindow = new BMap.InfoWindow(opts.content, opts);   
      this.map.openInfoWindow(infoWindow,point);  
    }
  },
}
</script>
<style lang="scss" scoped>
div{
  width: 1100px;
  height: 530px;
  overflow: hidden;
  margin: 0 auto;
}
</style>
