<template>
 <div class="yj-frame-main">
    <div class="up-banner">
      <h1>加入我们</h1>
      <img src="../../assets/image/about/1.png" alt="">
    </div>
    <div class="qyhxys swhz">
      <h1>项目合作</h1>
      <div class="swhzCon">
        <div class="left">
          <img src="../../assets/image/yjzh/men.png">
          <b>市场部</b>
          <span>18651649391</span>
        </div>
        <div class="right">
          <img src="../../assets/image/about/19.png">
          <span>电子邮箱：496127015@163.com</span>
        </div>
      </div>
    </div>
    <div class="FFFback">
      <div class="qyhxys yjzplb">
        <h1>人事招聘</h1>
        <div class="yjzp">
          <div class="left">
            <img src="../../assets/image/yjzh/women.png">
            <b>人事部</b>
            <span>025-58113035</span>
          </div>
          <div class="right">
            <img src="../../assets/image/about/15.png">
            <span>简历邮箱：504916473@qq.com</span>
          </div>
        </div>
        <div class="zplb">
          <zp-Table></zp-Table>
        </div>
      </div>
    </div>
    <div class="otherback">
      <div class="qyhxys gsdz">
        <h1>公司地址</h1>
         <div class="gsdzCon">
            <div class="top">
              <div>
                <img src="../../assets/image/about/18.png">
                <p>江苏省南京市鼓楼区郑和中路112号</p>
                <p>长江航运中心C座22楼</p>
              </div>
            </div>
            <map-locaiotn></map-locaiotn>
         </div>
      </div>
    </div>
    <div>
  </div>
</div>
</template>
<script>
import mapLocaiotn from "@/components/map.vue";
import zpTable from "./table.vue";
export default {
  name: "joinus",
  components: {
    mapLocaiotn,
    zpTable
  },
  data(){
    return {
        
    };
  },
  mounted() {

  },
  methods: {
   
  },
}
</script>
<style lang="scss" scoped>
.swhz{
  height: 260px;
  .swhzCon{
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 0.625rem;
  }
  div{
    padding: 1rem 2.25rem;
    display: flex;
    align-items: center;
  }
  .left{
    b{
      margin-left: 2rem;
      font-size: 0.9375rem;
    }
    span{
      font-weight: bold;
      // color: #FF9E48;
      color: #2D6CEE;
      margin-left: 3rem;
      font-size: 1.125rem;
      letter-spacing: 0.125rem;
      font-style: italic;
    }
    img{
      width: 22px;
    }
  }
  .right{
    span{
      margin-left: 1rem;
      font-weight: bold;
      color: #333333!important;
      letter-spacing: 2px;
    }
    img{
      width: 22px;
    }
  }
}
.otherback{
  background:url("~@/assets/image/about/16.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.gsdz{
  height: 900px;
  .gsdzCon{
    background: #FFFFFF;
    width: 100%;
    height: 740px;
    border-radius: 10px;
    .top{
      display: flex;
      justify-content: space-around;
      text-align: center;
      padding: 40px 0;
      div{
        background: #EEF6FF;
        padding: 1.5rem 1.5rem;
        width: 88%;
        border-radius: 0.625rem;
        display: flex;
        align-items: center;
        justify-content: left;
        img{
          width: 20px;
          margin-right: 20px;
        }
        p{
          line-height: 30px;
          letter-spacing: 2px;
          font-weight: bold;
        }
      }
    }
  }
}
.yjzp{
  background: #EEF6FF;
  border-radius: 0.625rem;
  padding: 1rem 2.25rem;
  display: flex;
  justify-content: space-between;
  div{
    padding: 1rem 2.25rem;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .left{
    b{
      margin-left: 2rem;
      font-size: 0.9375rem;
    }
    span{
      font-weight: bold;
      color: #FF9E48;
      margin-left: 3rem;
      font-size: 1.125rem;
      letter-spacing: 0.125rem;
      font-style: italic;
    }
    img{
      width: 24px;
    }
  }
  .right{
    span{
      margin-left: 1rem;
      font-weight: bold;
      color: #333333!important;
      letter-spacing: 2px;
    }
  }
}
.yjzplb{
  height: auto;
  padding-bottom: 20px;
}
.zplb{
  margin-top: 50px;
}
/deep/.pagination{
  margin: 2rem auto;
}
</style>
